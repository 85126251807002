<template>
    <div id="nav-top">
        <div class="layout-top flex align-center" :class="{'top-hover': show_center}">
            <el-image class="jincai-logo" :src="staticUrl + '/official/jincai_logo_yellow.png'" fit="contain" @click="backHome" style="cursor: pointer;width: 272px;" />

            <div class=" flex justify-center align-center" style="justify-content: space-around;cursor: pointer;margin-left: 30px;height: inherit;min-width: 800px">
                <div class="tab-item pos-r" v-for="(item, index) in tab_list" :key="index" @click="handleTab(item, index)">

                    <div class="flex align-center">
                        <el-image v-if="index===5" :src="staticUrl + '/official/icon/phone.png'" fit="cover" @click="backHome" style="cursor: pointer;width: 18px;height: 18px;margin-right: 8px" />
                        <div @click="switchTab(index)" class="text-center tab-font" @mouseenter="handleMove(item, index)" @mouseout="handleOut(item, index)">{{
                            item.tab_title }}</div>
                    </div>
                    <div class="pos-a" :class="{ 'tab-menu-active': tab_index === index, 'tab-menu': tab_index !== index }" @mouseout="handleOut(item, index)" style="box-shadow: 6px 8px 11px rgb(0, 0, 0, 0.2)">
                        <div v-for="(item_content,index_content) in item.content" :key="index_content" class="tab-menu-item" @click="knowMore(item_content)" @mouseenter="handleMove(item, index)">{{item_content}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pos-f nav" v-if="show_center" style="z-index:11;background-color:#fff;width: 100%;top:82px;padding-top:26px" @mouseleave="show_center=false">
            <div class="flex justify-center" style="min-width: 1270px">
                <div style="margin-right: 103px;">
                    <p class="mb-40" style="font-size: 24px;color: #333;">解决方案</p>
                    <div @click="toProgramme(1)" style="background: linear-gradient(180deg, #3A475E 0%, #1B2230 100%);;cursor: pointer;border-radius: 6px;padding: 20px 30px;margin-bottom:12px" class="flex align-center">
                        <el-image class="mr-15" :src="staticUrl + '/official/scheme/icon-1-black.png'" fit="cover" style="width: 48px;height: 48px;" />
                        <div>
                            <p class="mb-5" style="font-size: 24px;color: #fff;">建筑行业解决方案</p>
                            <p style="font-size: 16px;color: #fff;">合规解决人-机-料风险问题</p>
                        </div>
                    </div>
                    <div @click="toProgramme(4)" style="background: linear-gradient(180deg, #3A475E 0%, #1B2230 100%);cursor: pointer;border-radius: 6px;padding: 20px 30px;margin-bottom:12px" class="flex align-center">
                        <el-image class="mr-15" :src="staticUrl + '/official/scheme/icon-2-black.png'" fit="cover" style="width: 48px;height: 48px;" />
                        <div>
                            <p class="mb-5" style="font-size: 24px;color: #fff;">制造业解决方案</p>
                            <p style="font-size: 16px;color: #fff;">综合解决制造业财税难题</p>
                        </div>
                    </div>
                    <div @click="toProgramme(2)" style="background: linear-gradient(180deg, #3A475E 0%, #1B2230 100%);cursor: pointer;border-radius: 6px;padding: 20px 30px;margin-bottom:12px" class="flex align-center">
                        <el-image class="mr-15" :src="staticUrl + '/official/scheme/icon-3-black.png'" fit="cover" style="width: 48px;height: 48px;" />
                        <div>
                            <p class="mb-5" style="font-size: 24px;color: #fff;">医疗行业解决方案</p>
                            <p style="font-size: 16px;color: #fff;">解决医疗行业人-材合规难题</p>
                        </div>
                    </div>
                </div>
                <div style="margin-right: 66px;border-right: 1px solid #e0e0e0;width: fit-content;padding-right:54px">
                    <p class="mb-40" style="font-size: 24px;color: #333;">业务产品</p>
                    <div style="cursor: pointer;padding: 20px 30px;margin-bottom:12px" class="flex">
                        <div class="flex product-box" style="width:134px;margin-right: 56px" @click="toUrl(1)">
                            <el-image class="mr-10" :src="staticUrl + '/official/scheme/icon-4-black.png'" fit="cover" style="width: 28px;height: 28px;" />
                            <div class="product-font">
                                <p class="mb-5 product-font-title">车租宝</p>
                                <p class="product-font-content">企业私车公用</p>
                            </div>
                        </div>
                        <div class="flex product-box" @click="toUrl(8)">
                            <el-image class="mr-10" :src="staticUrl + '/official/scheme/icon-5-black.png'" fit="cover" style="width: 28px;height: 28px;" />
                            <div class="product-font">
                                <p class="mb-5 product-font-title">云物宝供应链</p>
                                <p class="product-font-content">大宗商品供应链交易平台</p>
                            </div>
                        </div>
                    </div>
                    <div style="cursor: pointer;padding: 20px 30px;margin-bottom:12px" class="flex">
                        <div class="flex product-box" style="width:134px;margin-right: 56px" @click="toUrl(2)">
                            <el-image class="mr-10" :src="staticUrl + '/official/scheme/icon-6-black.png'" fit="cover" style="width: 28px;height: 28px;" />
                            <div class="product-font">
                                <p class="mb-5 product-font-title">云服宝</p>
                                <p class="product-font-content">灵活用工</p>
                            </div>
                        </div>
                        <div class="flex product-box" @click="toUrl(5)">
                            <el-image class="mr-10" :src="staticUrl + '/official/scheme/icon-7-black.png'" fit="cover" style="width: 28px;height: 28px;" />
                            <div class="product-font">
                                <p class="mb-5 product-font-title">云税管家</p>
                                <p class="product-font-content">个体户及企业注册</p>
                            </div>
                        </div>
                    </div>
                    <div style="cursor: pointer;padding: 20px 30px;margin-bottom:12px" class="flex">
                        <div class="flex product-box" style="width:134px;margin-right: 56px" @click="toUrl(3)">
                            <el-image class="mr-10" :src="staticUrl + '/official/scheme/icon-8-black.png'" fit="cover" style="width: 28px;height: 28px;" />
                            <div class="product-font">
                                <p class="mb-5 product-font-title">云租宝</p>
                                <p class="product-font-content">机械设备租赁</p>
                            </div>
                        </div>
                        <div class="flex product-box" @click="toUrl(6)">
                            <el-image class="mr-10" :src="staticUrl + '/official/scheme/icon-9-black.png'" fit="cover" style="width: 28px;height: 28px;" />
                            <div class="product-font">
                                <p class="mb-5 product-font-title">云货宝</p>
                                <p class="product-font-content">网络物流</p>
                            </div>
                        </div>
                    </div>
                    <div style="cursor: pointer;padding: 20px 30px;margin-bottom:12px" class="flex">
                        <div class="flex product-box" style="width:134px;margin-right: 56px" @click="toUrl(4)">
                            <el-image class="mr-10" :src="staticUrl + '/official/scheme/icon-10-black.png'" fit="cover" style="width: 28px;height: 28px;" />
                            <div class="product-font">
                                <p class="mb-5 product-font-title">云物宝</p>
                                <p class="product-font-content">集采集销</p>
                            </div>
                        </div>
                        <div class="flex product-box" @click="toUrl(7)">
                            <el-image class="mr-10" :src="staticUrl + '/official/scheme/icon-11-black.png'" fit="cover" style="width: 28px;height: 28px;" />
                            <div class="product-font">
                                <p class="mb-5 product-font-title">重点人群</p>
                                <p class="product-font-content">重点人群减免政策落地</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="margin: 148px 0 0 ">
                    <el-image class="mr-10" :src="staticUrl + '/official/scheme/qr-code.png'" fit="cover" style="width: 194px;height: 194px;" />
                    <p style="font-size: 24px;color: #333;">了解更多产品细节</p>
                    <p style="font-size: 16px;color: #777;">请扫码联系我们的财税专家</p>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "nav-top",
    props: {
        scroll_y: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            tab_list: [
                { tab_title: '首页' },
                { tab_title: '产品中心' },
                { tab_title: '供应链' },
                { tab_title: '云税聚合' },
                // { tab_title: '招聘信息' }, 
                { tab_title: '了解我们', content: ['发展历程', '股东介绍', '新闻资讯'] },
                { tab_title: '联系我们' },
                { tab_title: '190-6501-5824' }
            ],
            tab_index: undefined,
            show_center: false,//产品中心
        }
    },
    mounted() {

    },
    methods: {
        switchTab(index) {
            if (index === 6) {
                this.$router.push({ name: 'contact-us' })
            } else if (index === 5) {
                this.$router.push({ name: 'company-intro' })

            }
            // else if (index === 4) {
            //     this.$router.push({ name: 'company-recruit' })

            // } 
            else if (index === 1) {

            } else if (index === 2) {
                this.$router.push({ name: 'chain' })
            } else if (index === 3) {
                // this.$router.push({ name: 'juhe' })
                window.location.href = 'https://jh.yunshuiccc.com/#/home/index'
            } else {
                this.backHome()
            }
        },
        handleScroll() {
            // 处理滚动事件的逻辑
            const scrollY = window.scrollY
            this.scroll_y = scrollY
            // console.log(scrollY);
        },
        //官网顶部tab栏
        handleTab(item, index) {
            console.log(item, '????ittititititiem');
            localStorage.setItem('nav_index', index)
            if (item.tab_title === '产品中心') {
                // this.show_center = true
            }
        },
        knowMore(type) {
            console.log(type);
            if (type === '发展历程') {
                this.$router.push({ name: 'company-history' })
            }
            if (type === '股东介绍') {
                this.$router.push({ name: 'company-shareholders' })
            }
            if (type === '新闻资讯') {
                this.$router.push({ name: 'news-list' })
            }
            // this.$router.push({ name: 'corporate-culture' })
        },
        handleMove(item, index) {
            this.tab_index = index
            console.log(item, index, '????index');
            if (index === 1) {
                this.show_center = true
            } else {
                this.show_center = false
            }
        },
        handleOut(item, index) {
            this.tab_index = undefined
        },
        backHome() {
            console.log(this.$router, '?????Luyou');
            this.$router.push({ name: 'home' })
        },
        isMobile() {
            console.log(navigator.userAgent);
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            return flag;
        },
        out() {
            console.log('????aaaaa');
        },
        //产品中心跳转type 1 车租宝 2云服宝
        toUrl(type) {
            if (type === 1) {
                this.$router.push({ name: 'chezubao' })
            } else if (type === 2) {
                this.$router.push({ name: 'yunfubao' })
            } else if (type === 3) {
                this.$router.push({ name: 'yunzubao' })
            } else if (type === 4) {
                this.$router.push({ name: 'yunwubao' })
            } else if (type === 5) {
                this.$router.push({ name: 'yunshuiguanjia' })
            } else if (type === 6) {
                this.$router.push({ name: 'yunhuobao' })
            } else if (type === 7) {
                this.$router.push({ name: 'important' })
            } else if (type === 8) {
                this.$router.push({ name: 'chain' })
            } else {
                console.log(113013901930193);
            }
        },

        //解决方案跳转
        toProgramme(type) {
            if (type === 1) {
                this.$router.push({ name: 'programme-architecture' })
            } else if (type === 2) {
                this.$router.push({ name: 'programme-medical' })
            } else if (type === 3) {
                this.$router.push({ name: 'yunzubao' })
            } else {
                this.$router.push({ name: 'programme-manufacture' })

            }
        }
    }
};
</script>
  
<style scoped lang="scss">
.product-font {
    &:hover {
        font-weight: bold;
    }
    &-title {
        font-size: 24px;
        color: #333;
    }

    &-content {
        font-size: 16px;
        color: #777;
    }
}

.product-box {
    &:hover {
        // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    }
}

@media (min-width: 805px) {
    .jincai-logo {
        margin-left: 7%;
        height: 68px;
        min-width: 272px;
    }

    .layout-top {
        width: 100%;
        max-height: 82px;
        position: fixed;
        top: 0;
        z-index: 12;
        background: rgb(255, 255, 255, 0.9);

        &:hover {
            // box-shadow: 6px 8px 11px rgb(0, 0, 0, 0.2);
            background: #fff;
            transition: 0.3s;
        }
    }

    .top-hover {
        // box-shadow: 6px 8px 11px rgb(0, 0, 0, 0.2);
        background: #fff;
    }

    //导航栏item样式
    .tab-item {
        font-size: 16px;
        color: #fff;
        line-height: 6.5;
        // padding: 0 30px;
    }
    .tab-font {
        color: #333;
        &:hover {
            color: #ff7701;
            font-weight: 600;
            transition: 0.1s;
        }
    }

    //导航栏下拉框菜单样式
    .tab-menu {
        // opacity: 0;
        display: none;

        &-active {
            // opacity: 1;
            // transition: block .4s;
            display: block;
            top: 104px;
            left: -1px;
            width: 100%;
            border-radius: 4px;
            background: #ffffff;
            color: #333;
        }

        &-item {
            text-align: center;
            font-size: 14px;
            line-height: 38px;
            height: 38px;
            border-radius: 4px;
        }

        &-item:hover {
            color: #fff;
            background-color: #1977fb;
        }
    }

    .nav {
        animation: out 0.25s ease-out;
        box-shadow: 6px 8px 11px rgb(0, 0, 0, 0.2);
    }

    @keyframes out {
        from {
            transform: translateY(-100%);
        }
        to {
            transform: translateY(0);
        }
    }
}

@media (max-width: 805px) {
    .layout-top {
        width: 100%;
        min-height: 35px;
    }
}
</style>
  
<template>
    <div id="app">
        <nav-top :scroll_y="scroll_y"></nav-top>
        <side-bar></side-bar>
        <router-view />
    </div>
</template>


<script>
// import { topicList } from "@/api/index";
import navTop from "@/components/nav-top.vue";
import SideBar from './components/side-bar.vue';
export default {
    name: 'App',
    components: {
        navTop,
        SideBar
    },
    data() {
        let nav_index = localStorage.getItem('nav_index')
        let window_url = window.location.href
        return {
            tab_list: [{ tab_title: '首页' }, { tab_title: '产品中心' }, { tab_title: '招聘信息' }, { tab_title: '了解我们', content: ['发展历程', '股东介绍'] }, { tab_title: '联系我们' }, { tab_title: '190-6501-5824' }],
            tab_index: undefined,
            scroll_y: 0,
            show_center: false,//产品中心
            nav_index,
            window_url,
        };
    },
    async created() {
        console.log(this.nav_index);
        // let res = await topicList()
        if (this.isMobile()) {
            // alert("手机端");
            //跳转到你手机路由
            window.location.href = 'https://www.yunshuiccc.com/h5'
        } else {
            // alert("pc端");
            //跳转到PC端路由
            // window.location.href = 'http://website.yunshuiccc.com'
        }
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        switchTab(index) {
            if (index === 4) {
                this.$router.push({ name: 'contact-us' })
            } else if (index === 3) {
                this.$router.push({ name: 'company-intro' })

            } else if (index === 2) {
                this.$router.push({ name: 'company-recruit' })

            } else if (index === 1) {
                this.show_center = true
            } else {
                this.backHome()
            }
        },
        handleScroll() {
            // 处理滚动事件的逻辑
            const scrollY = window.scrollY
            this.scroll_y = scrollY
        },
        //官网顶部tab栏
        handleTab(item, index) {
            console.log(item, '????ittititititiem');
            if (item.tab_title === '产品中心') {
                this.show_center = true
            }
        },
        knowMore(type) {
            console.log(type);
            if (type === '发展历程') {
                this.$router.push({ name: 'company-history' })
            }
            if (type === '股东介绍') {
                this.$router.push({ name: 'company-shareholders' })
            }
            // this.$router.push({ name: 'corporate-culture' })
        },
        handleMove(item, index) {
            this.tab_index = index
        },
        handleOut(item, index) {
            this.tab_index = undefined
        },
        backHome() {
            console.log(this.$router, '?????Luyou');
            this.$router.push({ name: 'home' })
        },
        isMobile() {
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            return flag;
        }

    }
};
</script>

<style lang="scss">
* {
    margin: 0;
    padding: 0;
}
</style>



import Vue from "vue";
import VueRouter from "vue-router";
import ViewUI from "view-design";

Vue.use(ViewUI);
Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "home",
    },
    //首页
    {
        path: "/home",
        name: "home",
        component: () => import("@/views/index.vue"),
        children: [],
        meta: {
            title: "金财云税-数字化财税聚合平台",
        },
    },
    //联系我们
    {
        path: "/contact",
        name: "contact-us",
        component: () => import("@/views/contact/index.vue"),
    },
    //企业简介
    {
        path: "/intro",
        name: "company-intro",
        component: () => import("@/views/company/intro.vue"),
    },
    //招聘信息
    {
        path: "/recruit",
        name: "company-recruit",
        component: () => import("@/views/recruit/index.vue"),
    },
    //企业文化
    {
        path: "/intro/corporate-culture",
        name: "corporate-culture",
        component: () => import("@/views/company/corporate-culture.vue"),
    },
    //企业发展历程
    {
        path: "/history",
        name: "company-history",
        component: () => import("@/views/company/history.vue"),
    },
    //企业发展历程
    {
        path: "/shareholders",
        name: "company-shareholders",
        component: () => import("@/views/company/shareholders.vue"),
    },
    {
        path: "/czb",
        name: "chezubao",
        component: () => import("@/views/czb/index.vue"),
    },
    {
        path: "/yfb",
        name: "yunfubao",
        component: () => import("@/views/yfb/index.vue"),
    },
    {
        path: "/yzb",
        name: "yunzubao",
        component: () => import("@/views/yzb/index.vue"),
    },
    {
        path: "/ywb",
        name: "yunwubao",
        component: () => import("@/views/ywb/index.vue"),
    },
    {
        path: "/ysgj",
        name: "yunshuiguanjia",
        component: () => import("@/views/ysgj/index.vue"),
    },
    {
        path: "/yhb",
        name: "yunhuobao",
        component: () => import("@/views/yhb/index.vue"),
    },
    {
        path: "/important",
        name: "important",
        component: () => import("@/views/important/index.vue"),
    },
    {
        path: "/news",
        name: "news",
        component: () => import("@/views/news/index.vue"),
    },
    {
        path: "/chain",
        name: "chain",
        component: () => import("@/views/chain/index.vue"),
    },
    {
        path: "/juhe",
        name: "juhe",
        component: () => import("@/views/juhe/index.vue"),
    },
    {
        path: "/news/list",
        name: "news-list",
        component: () => import("@/views/news/list.vue"),
    },
    {
        path: "/help",
        name: "help-center",
        component: () => import("@/views/help/index.vue"),
    },
    {
        path: "/programme/architecture",
        name: "programme-architecture",
        component: () => import("@/views/programme/architecture.vue"),
    },
    {
        path: "/programme/medical",
        name: "programme-medical",
        component: () => import("@/views/programme/medical.vue"),
    },
    {
        path: "/programme/manufacture",
        name: "programme-manufacture",
        component: () => import("@/views/programme/manufacture.vue"),
    },
];

const scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition && to.meta.keepAlive) {
        return savedPosition;
    }
    return { x: 0, y: 0 };
};

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior,
});

router.beforeEach((to, from, next) => {
    ViewUI.LoadingBar.start();
    // document.title = to.meta.title ? to.meta.title : '金财云税-数字化财税聚合平台'
    next();
});

router.afterEach((route) => {
    ViewUI.LoadingBar.finish();
});

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch((err) => err);
};

export default router;

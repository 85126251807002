import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/styles/index.scss"; // global css
import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";

Vue.config.productionTip = false;
Vue.prototype.staticUrl = process.env.VUE_APP_STATIC_URL; //全局定义静态资源服务器域名地址staticUrl
Vue.use(ElementUI); //全局引用elementui组件
Vue.use(ViewUI); //全局引用ViewUI组件

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

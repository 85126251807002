<template>
    <div id="side-bar">
        <div class="mb-25" v-for="(item,index) in type_list" :key="index" style="cursor:pointer;" @mouseenter="(e)=>enter(e,index)" @click="sideButton(index)">
            <el-popover placement="left-start" width="288" :trigger="index===0||index===2 ||index===1 ? 'hover':'manual'">
                <div v-if="index===0">
                    <div class="flex ml-15">
                        <img :src="staticUrl+`/official/side-bar/profile.png`" style="width: 48px;height: 48px;margin: 0 10px 0px 0;display:block">
                        <div>
                            <p style="color:#333333;font-size: 16px;margin-bottom:6px">添加金财云税·税务顾问</p>
                            <p style="color:#777777;font-size: 14px;">了解更多金财业务</p>
                        </div>
                    </div>
                    <img :src="staticUrl+`/official/side-bar/qr-code-expert.jpg`" style="width: 256px;height: 256px;margin: 0 auto 0;display:block">
                </div>
                <div v-if="index===1">
                    <div class="flex ml-15">
                        <img :src="staticUrl+`/official/side-bar/partner.png`" style="width: 48px;height: 48px;margin: 0 10px 0px 0;display:block">
                        <div>
                            <p style="color:#aaa;font-size: 16px;margin-bottom:3px">添加金财云税·税务顾问</p>
                            <p style="color:#333;font-size: 20px;">190-6501-1582</p>
                        </div>
                    </div>
                </div>
                <div v-if="index===2">
                    <p style="color:#333333;font-size: 16px;margin-bottom:6px">温馨提示</p>
                    <p style="color:#AAAAAA;font-size: 14px;">请前往云税聚合小程序，手机端查阅更方便</p>
                    <img :src="staticUrl+`/official/side-bar/qr-code-logo.jpg`" style="width: 226px;height: 226px;margin: 0 auto 0;display:block">
                    <p class="text-center mt-10" style="color:#AAAAAA;font-size: 14px;">微信扫码登录</p>
                </div>
                <div slot="reference">
                    <img :src="staticUrl+`/official/side-bar/side-bar-${index}.png`" style="width: 26px;height: 26px;margin: 0 auto 4px;display:block">
                    <p class="text-center">{{item}}</p>
                </div>
            </el-popover>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "side-bar",
    props: {},
    data() {
        return {
            type_list: [
                '微信咨询', '电话咨询', '下载小程序', '返回顶部'
            ],
            type_index: undefined,
            visible: false,
        }
    },
    mounted() {

    },
    methods: {
        enter(e, index) {
            // console.log(e, '??', index);
        },
        sideButton(index) {
            if (index === 3) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
    }
};
</script>
  
<style scoped lang="scss">
#side-bar {
    position: fixed;
    right: 2%;
    bottom: 25%;
    width: 92px;
    height: 312px;
    background: #fff;
    z-index: 9999;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    padding: 15px 0;
}
</style>
  